import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Stack
} from '@mui/material'
function CourseList({ courses, loading }) {
  if (courses.length > 0 && !loading) {
    return (
      <>
        <Stack spacing={2} sx={{ mb: 2 }}>
          {courses.map((course, index) => (
            <Card
              key={index}
              sx={{ minWidth: 275, minHeight: 300, borderRadius: '1em' }}
            >
              <CardContent>
                <CardMedia
                  component="img"
                  image={course.thumbnail}
                  sx={{ borderRadius: '1em', height: '200px' }}
                />
                <Typography
                  variant="h5"
                  component="h1"
                  mt={1}
                  sx={{ color: '#671D2D' }}
                >
                  {course.header}
                </Typography>
                <Typography component="h5" sx={{ color: '#A0AEC0' }}>
                  {course.description}
                </Typography>
                <Stack
                  sx={{ mt: 2 }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    sx={{ width: '100px', borderRadius: '1em' }}
                    color="secondary"
                    onClick={() => window.open(course.url, '_blank')}
                  >
                    รายละเอียด
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ width: '100px', borderRadius: '1em' }}
                    color="secondary"
                    onClick={() =>
                      window.open(
                        'https://www.m.me/LittleRoseHomeMadePastry',
                        '_blank'
                      )
                    }
                  >
                    สมัครเรียน
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </>
    )
  } else if (courses.length === 0 && !loading) {
    return (
      <>
        <Typography
          variant={'h5'}
          sx={{ textAlign: 'center', height: '100vh' }}
        >
          ไม่พบข้อมูล
        </Typography>
      </>
    )
  } else {
    return (
      <>
        <Typography
          variant={'h5'}
          sx={{ textAlign: 'center', height: '100vh' }}
        >
          Loading...
        </Typography>
      </>
    )
  }
}

export default CourseList
