import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  LinearProgress,
  Grid,
  Typography,
  Stack,
  ButtonBase
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslation } from 'react-i18next'
import { MainLayout } from 'layouts'
import { useAuth, useLesson } from 'hooks'

const History = () => {
  const history = useHistory()
  const { t } = useTranslation('course')
  const { loading, getAllWatchHistory } = useLesson()
  const [watchHistory, setWatchHistory] = useState([])
  const { user } = useAuth()
  useEffect(() => {
    async function fetchData() {
      const result = await getAllWatchHistory()
      setWatchHistory(result)
    }
    fetchData()
  }, [user, getAllWatchHistory])

  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  function LastLesson(data, index) {
    return (
      <Grid item my={2} key={`course-key-${index}`}>
        <Card
          sx={{
            display: 'flex',
            borderRadius: 5
          }}
          onClick={() => {
            history.push(
              `/course/${data?.course.id}/${
                data?.lesson.id
              }/${(data?.duration).toFixed(2)}`
            )
          }}
        >
          <ButtonBase>
            <CardMedia
              component="img"
              sx={{ width: 110, height: 110 }}
              image={data?.course.thumbnail}
              alt={data?.lesson.title}
            />

            <CardContent>
              <Typography
                component="h4"
                variant="title"
                style={{
                  textAlign: 'left',
                  color: '#EB557A'
                }}
              >
                {data?.lesson.title}
              </Typography>
              <Stack direction="row" spacing={1} mt={1}>
                <AccessTimeIcon
                  sx={{ fontSize: 18 }}
                  style={{
                    color: '#EB557A'
                  }}
                />
                <Typography
                  variant="body2"
                  paragraph
                  style={{
                    color: '#EB557A'
                  }}
                >
                  {`${Math.floor(data?.duration / 60)}.${(
                    data?.duration -
                    Math.floor(data?.duration / 60) * 60
                  ).toFixed(0)} ${t('course.minute')} `}
                </Typography>
              </Stack>
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
    )
  }

  return (
    <>
      <MainLayout title={t('course.history')} loading={loading} goBack>
        {!loading ? (
          <Container maxWidth="sm" sx={{ p: 0 }}>
            <Box px={3}>
              {watchHistory.length > 0 ? (
                watchHistory.map((lesson, index) => LastLesson(lesson, index))
              ) : (
                <Box textAlign="center" mt="30px">
                  <Typography
                    variant="body2"
                    paragraph
                    style={{
                      fontSize: '20px',
                      color: '#EB557A'
                    }}
                  >
                    {t('course.noHistory')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Container>
        ) : (
          <LinearProgress />
        )}
      </MainLayout>
    </>
  )
}

export default History
