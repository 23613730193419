/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

export const Layout = ({ children, style }) => {
  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }
  return <Box style={style}>{children}</Box>
}

export default Layout
