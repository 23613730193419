/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth'

import { db, functions } from 'configs'

import { setDoc, getDoc, doc } from 'firebase/firestore'

import { useAppContext } from 'context'
import { SUCCESS, ERROR } from 'configs'
import { httpsCallable } from 'firebase/functions'

const createAccount = httpsCallable(functions, 'createAccount')

const auth = getAuth()

function useAuth() {
  const { user, setUser, setError, setMessage } = useAppContext()
  const [loading, setLoading] = useState(false)

  // Get user login state
  useEffect(() => {
    setLoading(true)

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        getProfile(user)
      } else {
        setUser(user)
        setLoading(false)
      }
    })

    return () => unsubscribe()
  }, [])

  async function getProfile(user) {
    const { uid } = user
    try {
      setLoading(true)
      const profileRef = doc(db, `profile`, uid)
      const profileSnapshot = await getDoc(profileRef)
      const profile = profileSnapshot.data()

      setUser({ ...user, profile: profile })

      setLoading(false)
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }

  // Login with email and password
  async function login(email, password) {
    setLoading(true)

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setLoading(false)
        setMessage({ type: SUCCESS, message: 'Log in successful' })
      })
      .then(() => {
        setLoading(false)
        setMessage({ type: SUCCESS, message: 'Log in successful' })
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })

        setLoading(false)
      })
  }

  // Sigup with Email and Password
  async function signupWithEmailAndPassword(values) {
    setLoading(true)

    return createAccount({ ...values, isAdmin: false })
      .then((res) => {
        setLoading(false)
        setMessage({ type: SUCCESS, message: 'Sign up successful' })
        return res
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })

        setLoading(false)
      })
  }

  async function resetPassword(email, password) {
    setLoading(true)

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage({ type: SUCCESS, message: 'Password reset email sent!' })
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setMessage({ message: error.message, type: ERROR })
        setLoading(false)
        // ..
      })
  }

  // Login with provider
  async function signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider()

    try {
      setLoading(true)
      const user = await auth.signInWithPopup(provider)

      setMessage({ type: SUCCESS, message: 'Login successful' })
      return user
    } catch (error) {
      setError(error)
      setMessage({ message: error.message, type: ERROR })
    } finally {
      setLoading(false)
    }
  }
  // Logout
  async function logout() {
    try {
      setLoading(true)

      await auth.signOut()

      setUser(null)

      setLoading(false)
      setMessage({ type: SUCCESS, message: 'Log out successful' })
    } catch (error) {
      setError(error)
      setMessage({ message: error.message, type: ERROR })

      setLoading(false)
    }
  }

  // Update password
  async function changePassword(currentPassword, newPassword) {
    try {
      setLoading(true)

      const user = auth.currentUser

      if (user !== null) {
        const credential = EmailAuthProvider.credential(
          user.email || '',
          currentPassword
        )

        await reauthenticateWithCredential(user, credential)

        updatePassword(user, newPassword)
          .then(() => {
            setLoading(false)

            setMessage({ type: SUCCESS, message: 'Update password successful' })
          })
          .catch((error) => {
            console.log(error)
            setError(error)
            setMessage({ message: error.message, type: ERROR })

            setLoading(false)
          })
      }
    } catch (error) {
      console.log(error)

      setError(error)
      setMessage({ message: error.message, type: ERROR })

      setLoading(false)
    }
  }

  // Update email
  async function updateEmail(email, password) {
    try {
      setLoading(true)

      const user = auth.currentUser

      if (user !== null) {
        const credential = auth.EmailAuthProvider.credential(
          user.email || '',
          password
        )

        await user.reauthenticateWithCredential(credential)

        await user.updateEmail(email)

        setMessage({ type: SUCCESS, message: 'Update email successful' })
      }

      setLoading(false)
    } catch (error) {
      setError(error)
      setMessage({ message: error.message, type: ERROR })

      setLoading(false)
    }
  }

  // Update user profile
  async function updateProfile(profile) {
    try {
      setLoading(true)

      const user = auth.currentUser

      if (user !== null) {
        await setDoc(doc(db, 'profile', user.uid), profile, {
          merge: true
        })
        getProfile(user)
        setMessage({ type: SUCCESS, message: 'Update profile successful' })
      }

      setLoading(false)
    } catch (error) {
      setError(error)
      setMessage({ message: error.message, type: ERROR })

      setLoading(false)
    }
  }

  return {
    login,
    signupWithEmailAndPassword,
    signInWithFacebook,
    logout,
    changePassword,
    updateEmail,
    updateProfile,
    resetPassword,
    user,
    isLogin: !!user,
    loading
  }
}

export { useAuth }
