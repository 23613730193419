import { useState, useEffect } from 'react'
import StyledTextField from 'components/StyledTextField'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Layout } from 'layouts'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation.email.valid'))
      .required(t('validation.email.required')),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    phoneNumber: yup.string().min(10).max(10).required(),
    password: yup.string().required(t('validation.password.required')),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('validation.confirmPassword.required')
      )
  })

export function SignUp() {
  let history = useHistory()
  const [showPassword, setShow] = useState(false)

  const { t } = useTranslation('signup')

  const { signupWithEmailAndPassword, loading } = useAuth()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema(t))
  })

  const errors = formState.errors

  const handleClickShowPassword = () => {
    setShow(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onSubmit = async (values) => {
    const res = await signupWithEmailAndPassword(values)
    if (res) {
      history.push('/')
    }
  }

  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <Layout
      style={{
        backgroundColor: '#F9BEBA'
      }}
    >
      <Container
        component="main"
        maxWidth="sm"
        style={{
          minHeight: '100vh',
          backgroundColor: '#F9BEBA'
        }}
      >
        <IconButton
          id="btnGoback"
          color="white"
          aria-label="Goback"
          sx={{ mt: 2 }}
          onClick={() => history.push('/')}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box
          sx={{
            // paddingTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'grey'
          }}
        >
          <Typography component="h1" variant="title" color="#fff">
            {t('title')}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1, px: 3 }}
          >
            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  color="white"
                  id="inputEmail"
                  label={t('email')}
                  {...register('email')}
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              </Box>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  color="white"
                  id="inputPassword"
                  type={showPassword ? 'text' : 'password'}
                  label={t('password')}
                  {...register('password')}
                  error={!!errors?.password?.message}
                  helperText={errors?.password?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ mr: 0.5 }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  id="inputPasswordConfirm"
                  type={showPassword ? 'text' : 'password'}
                  color="white"
                  label={t('rePassword')}
                  {...register('passwordConfirmation')}
                  error={!!errors?.passwordConfirmation?.message}
                  helperText={errors?.passwordConfirmation?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ mr: 0.5 }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  color="white"
                  id="inputFirstname"
                  label={t('firstname')}
                  {...register('firstname')}
                  error={!!errors?.firstname?.message}
                  helperText={errors?.firstname?.message}
                />
              </Box>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  color="white"
                  id="inputLastname"
                  label={t('lastname')}
                  {...register('lastname')}
                  error={!!errors?.lastname?.message}
                  helperText={errors?.lastname?.message}
                />
              </Box>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PhoneIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  color="white"
                  id="inputPhoneNumber"
                  label={t('tel')}
                  {...register('phoneNumber')}
                  error={!!errors?.phoneNumber?.message}
                  helperText={errors?.phoneNumber?.message}
                  type="number"
                />
              </Box>
            </FormControl>
            <Box px={1}>
              <LoadingButton
                color="white"
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                loadingIndicator={t('buttonLoading')}
                sx={{
                  mt: 5,
                  mb: 2,
                  color: '#FFF',
                  backgroundColor: '#671D2D'
                }}
                style={{ borderRadius: 20 }}
              >
                <Typography component="h1">{t('btnSignUp')}</Typography>
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default SignUp
