/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import {
  Container,
  Box,
  FormControl,
  TextField,
  CardContent,
  Stack,
  InputAdornment,
  IconButton
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'hooks'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const validationSchema = (t) => {
  /* istanbul ignore next */
  return yup.object().shape({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmNewPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null])
  })
}

export const ChangePassword = () => {
  const { t } = useTranslation('account')
  const { loading, changePassword } = useAuth()
  const [showPassword, setShow] = useState(false)
  const [showNewPassword, setShowNew] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNew] = useState(false)

  const onSubmit = async (values) => {
    await changePassword(values.currentPassword, values.newPassword)
  }

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema(t))
  })

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClickShowPassword = (type) => {
    if (type === 'password') {
      setShow(!showPassword)
    } else if (type === 'newPassword') {
      setShowNew(!showNewPassword)
    } else {
      setShowConfirmNew(!showConfirmNewPassword)
    }
  }

  return (
    <Box mt={2}>
      <Container component="main" maxWidth="sm">
        <Box>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <Controller
                    name="currentPassword"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`profile.currentPassword`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword('password')
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ mr: 0.5 }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="newPassword"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`profile.newPassword`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword('newPassword')
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ mr: 0.5 }}
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="confirmNewPassword"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <TextField
                        label={t(`profile.confirmNewPassword`)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword('confirmNewPassword')
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ mr: 0.5 }}
                              >
                                {showConfirmNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>

                <Box display="flex" justifyContent="center">
                  <LoadingButton
                    id="btnUpdateProfile"
                    color="primary"
                    type="submit"
                    variant="contained"
                    style={{ color: 'white' }}
                    loading={loading}
                    loadingIndicator={t('profile.buttonLoading')}
                    sx={{
                      borderRadius: 20,
                      width: 150
                    }}
                  >
                    {t(`profile.buttonSave`)}
                  </LoadingButton>
                </Box>
              </Stack>
            </form>
          </CardContent>
        </Box>
      </Container>
    </Box>
  )
}

export default ChangePassword
