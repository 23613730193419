import ReactDOM from 'react-dom'
import App from './pages/App'
import reportWebVitals from './reportWebVitals'
import LocalServiceWorkerRegister from './sw-register'

import { BrowserRouter as Router } from 'react-router-dom'
import 'locales'
import { ContextProvider } from 'context'
import ThemeProvider, { theme } from 'theme'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <ContextProvider>
        <App />
      </ContextProvider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
)

LocalServiceWorkerRegister()
reportWebVitals()
