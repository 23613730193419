/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  AppBar,
  IconButton,
  Toolbar,
  Box,
  Backdrop,
  Slide,
  Typography,
  CircularProgress,
  LinearProgress,
  Fade
} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import BottomNavigation from '../components/BottomNavigation'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks'
import { useAppContext } from 'context'
import Logo from '../littlerose-logo.png'

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export const MainLayout = (props) => {
  const { children, goBack } = props
  const { backdrop, setBackdrop } = useAppContext()
  const { user } = useAuth()
  let history = useHistory()

  useEffect(() => {
    if (user) {
      handleClose()
    } else if (user === null) {
      history.push('/signin')
    }
  }, [user])

  const handleClose = () => {
    setBackdrop(false)
  }

  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <div style={props.style}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HideOnScroll {...props}>
        <AppBar
          position="sticky"
          elevation={0}
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <Toolbar>
            {goBack && (
              <IconButton
                edge="start"
                id="btnGoback"
                color="white"
                aria-label="Goback"
                onClick={() => history.goBack()}
              >
                <ArrowBackIosNewIcon style={{ color: '#671D2D' }} />
              </IconButton>
            )}
            <Typography
              style={{
                flexGrow: 1,
                textAlign: 'center',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img
                alt="Little Rose Pastry"
                src={Logo}
                style={{ width: '150px' }}
              />
            </Typography>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {props.loading && <LinearProgress />}
      <Fade in={!props.loading}>
        <Box my={0} style={props.style}>
          {children}
        </Box>
      </Fade>
      {!goBack && <BottomNavigation />}
    </div>
  )
}

export default MainLayout
