import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyAycYA5YRqr9hyRunXmwodD_pCfgtmurdg',
  authDomain: 'littlerose-prod.firebaseapp.com',
  projectId: 'littlerose-prod',
  storageBucket: 'littlerose-prod.appspot.com',
  messagingSenderId: '961879683806'
})

const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const functions = getFunctions()

export { firebaseApp, db, storage, functions }
