import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

export const StyledTextField = styled(TextField)({
  'input-label': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    color: 'red'
  },
  input: {
    color: '#8C8C8C',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#8C8C8C'
    }
  },
  '& label.Mui-focused': {
    color: '#8C8C8C'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white'
  },
  '& .MuiOutlinedInput-root': {
    input: {
      padding: '10.5px 14px'
    },
    backgroundColor: 'white',
    borderRadius: 30,
    '& fieldset': {
      borderColor: 'white',
      borderRadius: 30
    },
    '&:hover fieldset': {
      borderColor: 'white',
      borderRadius: 30
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      borderRadius: 30
    }
  }
})

export default StyledTextField
