import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles'

// Create a theme instance.
export const theme = createTheme({
  overrides: {},
  palette: {
    primary: {
      main: '#F9BEBA'
    },
    secondary: {
      main: '#671D2D'
    },
    error: {
      main: '#FF5757'
    },
    warning: {
      main: '#ff9800'
    },
    info: {
      main: '#2196f3'
    },
    success: {
      main: '#4caf50'
    },
    white: {
      main: '#FFF'
    },
    text: {
      main: '#EB557A'
    }
  },
  typography: {
    fontFamily: ['DUCK', 'UID Deep sea', 'FC Lamoon Bold'].join(',')
  }
})

const ThemeProvider = ({ children, theme }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

export default ThemeProvider
