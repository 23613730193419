import { useState, useEffect } from 'react'
import StyledTextField from 'components/StyledTextField'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Layout } from 'layouts'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import IconButton from '@mui/material/IconButton'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import PersonIcon from '@mui/icons-material/Person'

const useStyles = makeStyles((theme) => ({
  input: {
    color: '#FFF'
  }
}))

const validationSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation.email.valid'))
      .required(t('validation.email.required'))
  })

export function ResetPassword() {
  const classes = useStyles()
  let history = useHistory()

  const { t } = useTranslation('resetpassword')
  const { resetPassword, loading } = useAuth()

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema(t))
  })

  const errors = formState.errors

  const onSubmit = async (values) => {
    const { email } = values

    await resetPassword(email)
  }

  const [didMount, setDidMount] = useState(false)

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <Layout
      style={{
        backgroundColor: '#F9BEBA'
      }}
    >
      <Container
        component="main"
        maxWidth="sm"
        style={{
          height: '120vh',
          backgroundColor: '#F9BEBA'
        }}
      >
        <IconButton
          id="btnGoback"
          color="white"
          aria-label="Goback"
          sx={{ mt: 2 }}
          onClick={() => history.goBack()}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box
          sx={{
            pt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="title" color="#fff">
            {t('title')}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1, px: 3 }}
            style={{ width: '100%' }}
          >
            <FormControl margin="normal" fullWidth>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon
                  sx={{ mr: 1, my: 0.5, color: '#FFF' }}
                  fontSize="large"
                />
                <StyledTextField
                  fullWidth
                  color="white"
                  inputProps={{ className: classes.input }}
                  id="inputEmail"
                  label={t('email')}
                  {...register('email')}
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                />
              </Box>
            </FormControl>
            <Box px={1}>
              <LoadingButton
                color="white"
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                loadingIndicator={t('buttonLoading')}
                sx={{
                  mt: 4,
                  mb: 2,
                  color: '#FFF',
                  backgroundColor: '#671D2D'
                }}
                style={{ borderRadius: 20 }}
              >
                {t('buttonSend')}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default ResetPassword
