/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Container, Typography, InputBase } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'

import { useTranslation } from 'react-i18next'
import { MainLayout } from 'layouts'
import { useLesson } from 'hooks'
import CourseList from 'components/CourseList'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.55),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.65)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '25ch',
      '&:focus': {
        width: '55ch'
      }
    }
  }
}))

const Homepage = () => {
  const { t } = useTranslation('home')
  const { getCourses, searchCourse } = useLesson()
  const [courses, setCourses] = useState()
  const [query, setQuery] = useState('')
  const [didMount, setDidMount] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const fetchData = async () => {
    const result = await getCourses()
    setCourses(result)
    setLoading(false)
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (query?.length > 0 && query !== '') {
        handleSearch(query)
      } else {
        fetchData()
      }
    }, 700)
    return () => clearTimeout(timeOutId)
  }, [query])

  const handleSearch = async (text) => {
    setLoading(true)
    const result = await searchCourse(text)
    setCourses(result)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }

  return (
    <MainLayout title={t('title')} style={{ backgroundColor: '#F9BEBA' }}>
      <Box sx={{ p: 0 }} minHeight="100vh">
        <Container maxWidth="sm" sx={{ pt: 1, pl: 5, pr: 5 }}>
          <Box height="200" textAlign="center">
            <Typography fontSize="23px" color="#671D2D" fontWeight={600}>
              Little Rose Pastry Online Course
            </Typography>
          </Box>
          <Box mt={3}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => setQuery(e.target.value)}
              />
            </Search>
          </Box>
          <Box mt={3}>
            <CourseList courses={courses || []} loading={isLoading} />
          </Box>
        </Container>
      </Box>
    </MainLayout>
  )
}

export default Homepage
