/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Stack,
  ButtonBase,
  Divider
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MainLayout } from 'layouts'
import { useAuth, useLesson } from 'hooks'

const MyCourses = () => {
  const history = useHistory()
  const { t } = useTranslation('course')
  const { loading, getCourses, getAllWatchHistory } = useLesson()
  const [watchHistory, setWatchHistory] = useState([])
  const [courses, setCourses] = useState([])
  const { user } = useAuth()

  useEffect(() => {
    if (user?.uid) {
      fetchData(user.uid)
    }
  }, [user])

  const fetchData = async (uid) => {
    const result = await getCourses(uid)
    const history = await getAllWatchHistory()
    setWatchHistory(history)
    setCourses(result)
  }

  function LastLesson(data, index) {
    return (
      <Grid item my={2} key={`course-key-${index}`}>
        <Card
          sx={{
            display: 'flex',
            borderRadius: 5,
            cursor: 'pointer'
          }}
          onClick={() => {
            history.push(
              `/course/${data?.course.id}/${
                data?.lesson.id
              }/${(data?.duration).toFixed(2)}`
            )
          }}
        >
          <ButtonBase>
            <CardMedia
              component="img"
              sx={{ width: 110, height: 110 }}
              image={data?.lesson?.thumbnail}
              alt={data?.lesson.title}
            />

            <CardContent>
              <Typography
                component="h4"
                style={{
                  textAlign: 'left',
                  color: '#EB557A'
                }}
              >
                {data?.course.header}
              </Typography>
              <Typography
                component="h5"
                style={{
                  textAlign: 'left',
                  color: '#EB557A'
                }}
              >
                {data?.lesson.title}
              </Typography>
              <Stack direction="row" spacing={1} mt={1}>
                <AccessTimeIcon
                  sx={{ fontSize: 18 }}
                  style={{
                    color: '#EB557A'
                  }}
                />
                <Typography
                  variant="body2"
                  paragraph
                  style={{
                    color: '#EB557A'
                  }}
                >
                  {`${Math.floor(data?.duration / 60)}.${(
                    data?.duration -
                    Math.floor(data?.duration / 60) * 60
                  ).toFixed(0)} ${t('course.minute')} `}
                </Typography>
              </Stack>
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
    )
  }
  function MyCourse(course, index) {
    return (
      <Grid item my={2} key={`course-key-${index}`}>
        <Card
          sx={{
            display: 'flex',
            borderRadius: 5,
            cursor: 'pointer'
          }}
          onClick={() => {
            history.push(`/course/${course?.id}`)
          }}
        >
          <ButtonBase>
            <CardMedia
              component="img"
              sx={{ width: 110, height: 110 }}
              image={course.thumbnail}
              alt={course.header}
            />
            <CardContent>
              <Typography
                component="h4"
                style={{
                  textAlign: 'left',
                  color: '#EB557A'
                }}
              >
                {course.header}
              </Typography>
              <Stack direction="row" spacing={1} mt={1}>
                <AccessTimeIcon
                  sx={{ fontSize: 18 }}
                  style={{
                    color: '#EB557A'
                  }}
                />
                <Typography
                  variant="body2"
                  paragraph
                  style={{
                    color: '#EB557A'
                  }}
                >
                  {`${course.minutes} ${t('course.minute')} `}
                </Typography>
              </Stack>
            </CardContent>
          </ButtonBase>
        </Card>
      </Grid>
    )
  }
  return (
    <MainLayout
      title={t('course.title')}
      loading={loading}
      style={{
        backgroundColor: '#F9BEBA',
        minHeight: '90vh'
      }}
    >
      {!loading ? (
        <Container maxWidth="sm" sx={{ p: 0 }}>
          <Typography
            component="h2"
            gutterBottom
            mt={2}
            variant="title"
            style={{
              textAlign: 'center',
              color: '#EB557A'
            }}
          >
            {t('course.history')}
          </Typography>
          <Box px={3}>
            {watchHistory.length > 0 ? (
              watchHistory.map(
                (lesson, index) => index === 0 && LastLesson(lesson, index)
              )
            ) : (
              <Box textAlign="center" mt="30px">
                <Typography
                  variant="body2"
                  paragraph
                  style={{
                    fontSize: '20px',
                    color: '#EB557A'
                  }}
                >
                  {t('course.noHistory')}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider />
          <Typography
            component="h2"
            mt={2}
            gutterBottom
            variant="title"
            style={{
              textAlign: 'center',
              color: '#EB557A'
            }}
          >
            {t('course.myCourse')}
          </Typography>
          <Box px={3}>
            {courses.every((course) => !course.subscribed) ? (
              <Box textAlign="center" mt="30px">
                <Typography
                  variant="body2"
                  paragraph
                  style={{
                    fontSize: '20px',
                    color: '#EB557A'
                  }}
                >
                  {t('course.noMyCourse')}
                </Typography>
              </Box>
            ) : (
              courses.map(
                (course, index) => course?.subscribed && MyCourse(course, index)
              )
            )}
          </Box>
        </Container>
      ) : (
        <LinearProgress />
      )}
    </MainLayout>
  )
}

export default MyCourses
