/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { MainLayout } from 'layouts'
import { useLesson, useAuth } from 'hooks'
import {
  Box,
  Grid,
  LinearProgress,
  ListItem,
  Tab,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
  ListItemText,
  ListItemIcon
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import VideoPlayer from 'components/Pages/Course/VideoPlayer'
import { Button } from 'components/Button'
import Image from 'components/Image'
import { FileDownload } from '@mui/icons-material'

import Description from 'components/Pages/Course/Description'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export const Course = () => {
  const { t } = useTranslation('course')
  const player = useRef()
  const theme = useTheme()
  const isLaptop = useMediaQuery(theme.breakpoints.up('sm'))
  const { getCourse, subscribeCourse, updateWatchHistory } = useLesson()
  const { id, lessonId, time } = useParams()
  const [course, setCourse] = useState()
  const [content, setContent] = useState()
  const { user } = useAuth()
  const [tab, setTab] = useState('1')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [didMount, setDidMount] = useState(false)
  const [loading, setLoading] = useState(true)
  const [seekked, setSeeked] = useState(false)

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  const fetchData = async (notRefresh) => {
    const result = await getCourse(id, notRefresh)
    const initSections = result?.lessons?.map((lesson, index) => ({
      ...lesson,
      expanded: index === 0
    }))

    setCourse({ ...result, lessons: initSections })
    if (lessonId) {
      let filterLesson = result?.lessons.filter(
        (lesson) => lesson.id === lessonId && { ...lesson }
      )
      setContent(filterLesson[0])
      player.current.seekTo(time)
    } else {
      // Play the first video of this course
      result?.subscribed && setContent(result?.lessons[0])
    }

    setLoading(false)
  }

  const handleSubscription = async () => {
    setDialogOpen(false)
    await subscribeCourse(id)
    fetchData()
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const playNext = () => {
    // @TODO
    // Play next content within same lesson
    // If lesson ended. Go next lesson
  }

  const videoProgress = (state) => {
    updateWatchHistory({
      course: course,
      lesson: content,
      duration: state.playedSeconds
    })
  }

  useEffect(() => {
    setDidMount(true)
    return () => setDidMount(false)
  }, [])

  if (!didMount) {
    return null
  }
  return (
    <MainLayout title={course?.header} goBack loading={loading}>
      {loading && <LinearProgress />}
      {course?.subscribed ? (
        <Grid container>
          <Grid item xs={12} sm={8} lg={9}>
            <VideoPlayer
              url={content?.video}
              onEnded={playNext}
              onProgress={videoProgress}
              player={player}
              onReady={() => {
                setTimeout(() => {
                  !seekked && player.current.seekTo(time)
                  setSeeked(true)
                }, 1000)
              }}
            />
            <Description
              title={content?.title}
              description={course?.description}
            />
            {!isLaptop ? (
              <TabContext value={tab}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}
                >
                  <TabList onChange={handleTabChange}>
                    <Tab label="Lesson" value="1" />
                    <Tab label="Document" value="2" />
                  </TabList>
                </Box>

                <Box>
                  <TabPanel
                    value="1"
                    sx={{
                      margin: 0,
                      padding: 0
                    }}
                  >
                    <Grid item xs={12} mt="10px">
                      {course?.lessons?.map((lesson, index) => (
                        <Box key={`lesson-index-${index}`} mb={1}>
                          <ListItem
                            style={{
                              backgroundColor:
                                content?.id === lesson.id && '#F9BEBA'
                            }}
                            key={`content-index-${index}`}
                            button
                            selected={content?.id === lesson.id}
                            onClick={() => {
                              setContent(lesson)
                              window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                              })
                            }}
                          >
                            <Box
                              style={{
                                width: '130px',
                                height: '75px',
                                borderRadius: '0.3em',
                                backgroundColor: '#eee'
                              }}
                            >
                              <Image
                                src={lesson.thumbnail}
                                alt={lesson.title}
                                style={{
                                  width: '130px',
                                  height: '75px',
                                  borderRadius: '0.3em'
                                }}
                              />
                            </Box>
                            <Box sx={{ ml: 2, fontFamily: 'FC Lamoon Bold' }}>
                              {lesson.title}
                            </Box>
                          </ListItem>
                        </Box>
                      ))}
                    </Grid>
                  </TabPanel>
                  <TabPanel
                    value="2"
                    sx={{
                      margin: 0,
                      padding: 0
                    }}
                  >
                    <Grid item xs={12} mt="10px">
                      {course?.documents
                        ?.sort((a, b) => a.order - b.order)
                        .map((document, index) => (
                          <Box key={`doc-index-${index}`} mb={1}>
                            <ListItem
                              key={`doc-index-${index}`}
                              button
                              onClick={() =>
                                window.open(`${document.file}`, '_blank')
                              }
                            >
                              <ListItemText
                                sx={{ fontFamily: 'FC Lamoon Bold' }}
                              >
                                {`${document.order}. ${document.title}`}
                              </ListItemText>
                              <ListItemIcon>
                                <FileDownload fontSize="md" />
                              </ListItemIcon>
                            </ListItem>
                          </Box>
                        ))}
                    </Grid>
                  </TabPanel>
                </Box>
              </TabContext>
            ) : (
              <TabContext value={tab}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: { xs: 'none', sm: 'block' }
                  }}
                >
                  <TabList onChange={handleTabChange}>
                    <Tab label="Documents" value="1" />
                  </TabList>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <TabPanel value="1">
                    <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                      {course?.documents
                        ?.sort((a, b) => a.order - b.order)
                        .map((document, index) => (
                          <ListItem
                            key={`doc-index-${index}`}
                            button
                            onClick={() =>
                              window.open(`${document.file}`, '_blank')
                            }
                          >
                            <ListItemText sx={{ fontFamily: 'FC Lamoon Bold' }}>
                              {`${document.order}. ${document.title}`}
                            </ListItemText>
                            <ListItemIcon>
                              <FileDownload fontSize="md" />
                            </ListItemIcon>
                          </ListItem>
                        ))}
                    </Grid>
                  </TabPanel>
                </Box>
              </TabContext>
            )}
          </Grid>

          {isLaptop && (
            <Grid
              item
              xs={12}
              sm={4}
              lg={3}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {course?.lessons?.map((lesson, index) => (
                <Box key={`lesson-index-${index}`} mb={1}>
                  <ListItem
                    key={`content-index-${index}`}
                    button
                    selected={content?.id === lesson.id}
                    style={{
                      backgroundColor: content?.id === lesson.id && '#F9BEBA'
                    }}
                    onClick={() => {
                      setContent(lesson)
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }}
                  >
                    <Box
                      style={{
                        width: '100px',
                        height: '56px',
                        borderRadius: '0.3em',
                        backgroundColor: '#eee'
                      }}
                    >
                      <Image
                        src={lesson.thumbnail}
                        alt={lesson.title}
                        style={{
                          width: '100px',
                          height: '56px',
                          borderRadius: '0.3em'
                        }}
                      />
                    </Box>
                    <Box sx={{ ml: 2, fontFamily: 'FC Lamoon Bold' }}>
                      {lesson.title}
                    </Box>
                  </ListItem>
                </Box>
              ))}
            </Grid>
          )}
        </Grid>
      ) : (
        <Container maxWidth="sm" justify="center" sx={{ p: 0 }}>
          <Grid>
            <Grid>
              <img
                src={course?.thumbnail}
                style={{ width: '100%', height: '100%' }}
                alt={course?.title}
                loading="lazy"
              />
            </Grid>
            <Grid p={1}>
              <Typography
                variant="body"
                component="h2"
                color="primary"
                mt={2}
                style={{ textAlign: 'center' }}
              >
                {course?.subheader}
              </Typography>
              <Description
                title={course?.title}
                description={course?.description}
              />
              {!loading && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => setDialogOpen(true)}
                  >
                    {t('course.buttonRegister')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t('subscription.title')}</DialogTitle>

            <DialogContent>
              <DialogContentText>{t('subscription.content')}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <MuiButton onClick={() => setDialogOpen(false)} color="primary">
                {t('subscription.buttonCancel')}
              </MuiButton>

              <MuiButton
                onClick={() => {
                  handleSubscription()
                }}
                color="primary"
                autoFocus
              >
                {t('subscription.buttonOk')}
              </MuiButton>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </MainLayout>
  )
}

export default Course
